<script setup lang="ts"></script>

<template>
  <div class="h-full flex flex-col">
    <SystemLayoutsMainMenu />
    <slot />
    <UserChat />
    <SystemLayoutsFooter />
  </div>
</template>
